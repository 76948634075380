import React, { useEffect, useState } from "react";
import axios from "axios"; // Assuming you're using Axios for HTTP requests
import "./App.css";
import { InfinitySpin } from "react-loader-spinner";
import ReactPlayer from "react-player";
import FeedbackComponent from "./FeedbackComponent";
import AudioPlayer from "./AudioPlayer";
import CustomAudioPlayer from "./CustomAudioPlayer";
import { buildQueries } from "@testing-library/react";

const positiveFeedback = [
  "You are nailing it! Your video presence is great. Keep going!",
  "Fantastic job! Your energy and enthusiasm levels are nice. Great work!",
  "Your video presence is spot on. You are mastering the art of engagement. Well done!",
  "Impressive! Your video presence is professional and personable. Keep it up!",
  "Excellent video presence! You've made a strong first impression. Well done!",
];

const negativeFeedback = [
  "Your video presence could be improved. Try smiling more to connect with the audience.",
  "To enhance your video presence, focus on maintaining a friendly and engaging demeanor.",
  "Your video presence needs improvement. Practice smiling naturally while speaking.",
  "Your video presence needs improvement. A warmer smile will make a big difference.",
  "Your video presence could be improved. Try injecting more enthusiasm into your delivery.",
];

function UserReport() {
  const [data, setData] = useState([]);
  const [username, setUsername] = useState();
  const [isIELTSscoring, setisIELTSscoring] = useState(false);
  const [mediaArray, setMediaArray] = useState([]);
  const [OnlymediaArray, setOnlyMediaArray] = useState([]);
  const [textArray, setTextArray] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showNote, setShowNote] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const senderId = queryParams.get("senderId");
        const starttime = queryParams.get("starttime");
        //
        const response = await axios.post(
          //for Production
          //"https://hnylearning.com/awarenessHubBackend/getData/getDetailedUserReportWithTextResponse/",
          //for interview.lifebharat.com
          //for test
          "https://hnylearning.com/awarenessHubBackend_UAT/getData/getDetailedUserReportWithTextResponse2/",
          //for botBuild
          //"https://hnylearning.com/telegramBot_LSB/report",
          {
            senderId: senderId,
          },
          {
            headers: {
              authtoken: "Awareness-Hub@7895",
              "Content-Type": "application/json",
            },
          }
        );

        console.log("response", response);

        //Making Changes for UAT

        setData(response.data.data);

        const MainData = response.data.data;
        console.log("MainData", MainData);

        const arr = MainData[0].response;
        const username = arr.userName;
        console.log("username", username);
        setUsername(username);

        const isIELTSscoring = arr.isIELTSscoring;
        console.log("isIELTSscoring", isIELTSscoring);
        setisIELTSscoring(isIELTSscoring);
        const mediaArray = arr.multimediaStagesObj;
        console.log("mediaArray", mediaArray);

        setMediaArray(mediaArray);
        // const hasRevisedOverallScore = mediaArray.some((video) =>
        //   video.hasOwnProperty("Revised_Overall_score")
        // );

        // Set showNote to true if Revised_Overall_score is found
        // if (hasRevisedOverallScore) {
        //   setShowNote(true);
        // }
        const OnlymediaArray = arr.onlyMediaStagesObj;
        console.log("OnlymediaArray", OnlymediaArray);
        setOnlyMediaArray(OnlymediaArray);
        const textArray = arr.textStagesObj;
        console.log("textArray", textArray);
        setTextArray(textArray);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  const getCategory = (value) => {
    if (value >= 0 && value < 4) {
      return "Low";
    } else if (value >= 4 && value < 8) {
      return "Moderate";
    } else {
      return "High";
    }
  };

  return (
    <>
      {loader ? (
        <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
          <InfinitySpin
            visible={true}
            width="150"
            color="#02ccfe"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      ) : null}

      <div className="main-container">
        {loader ? null : (
          <div style={{ paddingTop: "5px" }} className="sub-container">
            <div className="row text-center">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                {" "}
                <img width={150} className="logo" src="./hnylogo.png" />
              </div>
              <div className="col-md-4"></div>
            </div>
            <hr />
            <div style={{ marginTop: 30 }} className="row">
              <p className="main-heading">
                Here’s a quick report of <b>{username}</b>
              </p>
            </div>

            <div style={{ marginTop: 10, padding: 0 }} class="container">
              <ul
                style={{ border: 0 }}
                class="nav nav-tabs"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="video-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#video"
                    type="button"
                    role="tab"
                    aria-controls="video"
                    aria-selected="false"
                  >
                    Video Inputs
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="text-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#text"
                    type="button"
                    role="tab"
                    aria-controls="text"
                    aria-selected="true"
                  >
                    Text Inputs
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade"
                  id="text"
                  role="tabpanel"
                  aria-labelledby="text-tab"
                >
                  <div style={{ marginTop: 25 }} className="mt-10">
                    {textArray.map((section, sectionIndex) => {
                      const hasResults = section.data.some(
                        (item) => item.Result !== undefined
                      );
                      return (
                        <div key={sectionIndex}>
                          <h2
                            style={{
                              fontSize: 23,
                              fontWeight: 600,
                              marginTop: 50,
                            }}
                          >
                            {section.sectionName}
                          </h2>

                          {hasResults &&
                            section.sectionScoring !== 0 &&
                            section.sectionScoring !== "" && (
                              <>
                                <p className="fontsize">
                                  Section Score: <b>{section.sectionScore}</b>{" "}
                                  out of <b>{section.sectionScoring}</b> (
                                  {Math.round(
                                    (section.sectionScore /
                                      section.sectionScoring) *
                                      100
                                  )}
                                  %)
                                </p>
                              </>
                            )}
                          {section.data.map((item, itemIndex) => (
                            <div key={itemIndex}>
                              <p className="question">
                                <b style={{ width: 150 }}>
                                  {`Question ${itemIndex + 1}`}:{" "}
                                </b>

                                {`${item.BotText}`}
                              </p>
                              <p className="answertext">
                                <b>Answer: </b>
                                {item.userText}&nbsp;
                                <span>
                                  {item.Result ? (
                                    <>
                                      {item.Result == "RIGHT" ? (
                                        <>
                                          (
                                          <span
                                            style={{
                                              backgroundColor: "rgb(0 255 8)",
                                            }}
                                          >
                                            Right
                                          </span>
                                          )
                                        </>
                                      ) : (
                                        <>
                                          (
                                          <span
                                            style={{
                                              backgroundColor: "rgb(255 0 4)",
                                            }}
                                          >
                                            Wrong
                                          </span>
                                          )
                                        </>
                                      )}
                                    </>
                                  ) : null}
                                </span>
                              </p>
                              {/* {item.Score !== undefined && (
                              <p>
                                <strong>Score:</strong> {item.Score}
                              </p>
                            )}
                            <p>
                              <strong>Time:</strong> {item.time}
                            </p> */}
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  class="tab-pane fade show active"
                  id="video"
                  role="tabpanel"
                  aria-labelledby="video-tab"
                >
                  <div style={{ marginTop: 25 }} className="mt-10">
                    {mediaArray.map((item, index) => (
                      <div key={index} className="faq-item">
                        {/* Check if index is greater than 0 to start from index 1 */}
                        {item.type === "videoInput" && (
                          <>
                            <p style={{ marginTop: 30 }} className="question">
                              <b>{`Question ${index + 1}`}</b>
                              {`: ${item.BotText}`}
                            </p>

                            {isIELTSscoring == true ? (
                              // <div className="IELTS">
                              //   <div className="row">
                              //     <div className="col-md-3 scoresize">
                              //       Overall score:{" "}
                              //       <b>{item.Overall_score}/9.0</b>
                              //     </div>
                              //     <div className="col-md-3 scoresize">
                              //       Revelance score: <b>{item.Relevance}%</b>
                              //     </div>
                              //   </div>
                              //   <hr />
                              //   <div
                              //     style={{ marginTop: 5 }}
                              //     className="col-md-7"
                              //   >
                              //     <div className="row">
                              //       <div
                              //         style={{ fontSize: 15 }}
                              //         className="col-md-3 scoresize"
                              //       >
                              //         Fluency & Coherence:
                              //         <b>{item.Fluency}/9.0</b>
                              //       </div>
                              //       <div
                              //         style={{ fontSize: 15 }}
                              //         className="col-md-3 scoresize"
                              //       >
                              //         Lexical resource: <b>{item.Relevance}%</b>
                              //       </div>
                              //       <div
                              //         style={{ fontSize: 15 }}
                              //         className="col-md-3 scoresize"
                              //       >
                              //         Grammatical range & Accuracy:{" "}
                              //         <b>{item.Grammar}%</b>
                              //       </div>
                              //       <div
                              //         style={{ fontSize: 15 }}
                              //         className="col-md-3 scoresize"
                              //       >
                              //         Pronunciation:{" "}
                              //         <b>{item.Pronounciation}%</b>
                              //       </div>
                              //     </div>
                              //   </div>
                              // </div>
                              <div className="container row">
                                <div
                                  style={
                                    {
                                      //border: "1px solid rgba(0, 0, 0, 0.8)",
                                      //padding: 20,
                                      //borderRadius: 20,
                                    }
                                  }
                                  className="col-md-9"
                                >
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div
                                        style={{ marginTop: 30 }}
                                        className="text-center"
                                      >
                                        <h5 className="mb-3">Overall</h5>
                                        <div className="display-4 fw-bold text-primary">
                                          {item.Overall_score}/9.0
                                        </div>
                                      </div>
                                      <div className="text-center mb-4">
                                        <small className="text-muted">
                                          Topic relevance score:{" "}
                                          <strong>{item.Relevance}%</strong>
                                        </small>
                                      </div>
                                    </div>
                                    <div className="col-md-9">
                                      <div className="row">
                                        <div className="col-md-6 mb-3">
                                          <div className="card border-primary">
                                            <div className="card-body text-center">
                                              <h6 className="card-title">
                                                Fluency & Coherence
                                              </h6>
                                              <p className="display-6 text-success mb-0">
                                                {item.Fluency}/
                                                <span style={{ fontSize: 16 }}>
                                                  9.0
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <div className="card border-secondary">
                                            <div className="card-body text-center">
                                              <h6 className="card-title">
                                                Lexical Resource
                                              </h6>
                                              <p className="display-6 text-primary mb-0">
                                                {item.lexical_resource}/
                                                <span style={{ fontSize: 16 }}>
                                                  9.0
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <div className="card border-warning">
                                            <div className="card-body text-center">
                                              <h6 className="card-title">
                                                Grammatical Range & Accuracy
                                              </h6>
                                              <p className="display-6 text-warning mb-0">
                                                {item.Grammar}/
                                                <span style={{ fontSize: 16 }}>
                                                  9.0
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <div className="card border-danger">
                                            <div className="card-body text-center">
                                              <h6 className="card-title">
                                                Pronunciation
                                              </h6>
                                              <p className="display-6 text-danger mb-0">
                                                {item.Pronounciation}/
                                                <span style={{ fontSize: 16 }}>
                                                  9.0
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p>{item.transcription}</p>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{ marginTop: 16 }}
                                className="NonIELTS"
                              >
                                <div className="row">
                                  <div className="col-md-12 scoresize">
                                    <div>
                                      {/* {item.Overall_score = item.Overall_score<10 ? item.Overall_score :(item.Overall_score/10).toFixed(1) } */}
                                      {item.SampleAnswer ? (
                                        <p style={{ fontSize: 15 }}>
                                          <b>Benchmark Response:</b>{" "}
                                          {item.SampleAnswer}
                                        </p>
                                      ) : null}
                                      <hr />
                                      Overall Interview Score:&nbsp;
                                      {item.FinalOverallScore ? (
                                        <>
                                          {" "}
                                          <b> {item.FinalOverallScore}</b>
                                        </>
                                      ) : (
                                        <>{item.wordCounterror}</>
                                      )}
                                      <br />
                                      <p>
                                        Overall interview is based on{" "}
                                        <b>Relevance</b>,
                                        <b> Communication Impact</b> and
                                        <b> Executive Presence</b>
                                      </p>
                                      {/* {item.communicationScore ? (
                                        <>
                                          Communication Score :{" "}
                                          {item.communicationScore}
                                        </>
                                      ) : null}
                                      <br />
                                      {item.emotionScore ? (
                                        <>Emotion Score : {item.emotionScore}</>
                                      ) : null}
                                      <br />
                                      {item.executivePresenceScore ? (
                                        <>
                                          Executive Presence Score :{" "}
                                          {item.executivePresenceScore}
                                        </>
                                      ) : null} */}
                                      <hr />
                                      <p>
                                        <b>Relevance: </b> This is based on
                                        suitabilty of content
                                        <br />
                                        <b>Relevance score: </b>
                                        {item.Relevance ? (
                                          <>
                                            {" "}
                                            {getCategory(item.Relevance)} (
                                            {item.Relevance})
                                          </>
                                        ) : (
                                          <>{item.wordCounterror}</>
                                        )}{" "}
                                        <br />
                                        {item.relevance_suggestionPatternvalue ? (
                                          <>
                                            {" "}
                                            <b>Suggestion: </b>
                                            {
                                              item.relevance_suggestionPatternvalue
                                            }
                                          </>
                                        ) : null}
                                      </p>
                                      <p>
                                        <b>Communication Impact: </b> This is
                                        based on grammer, word choice, sentence
                                        structure and fluency
                                        <br />
                                        <b>Communication Impact score: </b>
                                        {item.communicationScore ? (
                                          <>
                                            {getCategory(
                                              item.communicationScore
                                            )}{" "}
                                            ({item.communicationScore}) <br />
                                          </>
                                        ) : (
                                          <>{item.wordCounterror}</>
                                        )}
                                        {item.comsug_value ? (
                                          <>
                                            {" "}
                                            <b>Suggestion: </b>
                                            {item.comsug_value}
                                          </>
                                        ) : null}
                                      </p>
                                      <p>
                                        <b>Executive Presence: </b> This is
                                        based on tone, professionalism and
                                        authority
                                        <br />
                                        <b>Executive Presence score: </b>
                                        {item.executivePresenceScore ? (
                                          <>
                                            {" "}
                                            {getCategory(
                                              item.executivePresenceScore
                                            )}{" "}
                                            ({item.executivePresenceScore}){" "}
                                            <br />
                                          </>
                                        ) : (
                                          <>{item.wordCounterror}</>
                                        )}
                                        {item.epsugg_value ? (
                                          <>
                                            <b>Suggestion: </b>
                                            {item.epsugg_value}
                                          </>
                                        ) : null}
                                      </p>
                                      {item.dominantEmotion ? (
                                        <>
                                          <p>
                                            <b>Predominant Emotion: </b>
                                            {item.dominantEmotion ===
                                            "happy" ? (
                                              <b>
                                                High level of energy and
                                                confidence
                                              </b>
                                            ) : null}
                                            {item.dominantEmotion === "fear" ? (
                                              <b>Low level of confidence</b>
                                            ) : null}
                                            {item.dominantEmotion ===
                                            "disgust" ? (
                                              <b>
                                                Negative body language and
                                                disengagement
                                              </b>
                                            ) : null}
                                            {item.dominantEmotion ===
                                            "angry" ? (
                                              <b>
                                                Negative body language and
                                                Aggression
                                              </b>
                                            ) : null}
                                            {item.dominantEmotion === "sad" ? (
                                              <b>
                                                Low level of  energy and
                                                confidence
                                              </b>
                                            ) : null}
                                            {item.dominantEmotion ===
                                            "surprise" ? (
                                              <b>
                                                Negative body language
                                                presenting low level of
                                                readiness
                                              </b>
                                            ) : null}
                                          </p>
                                        </>
                                      ) : null}
                                      {/* 
//
//
// */}
                                      {/* {item.relevanceAnalysis ? (
                                        <p>
                                          <b>
                                            AI-Generated 'Relevance Analysis'
                                            Summary :
                                          </b>{" "}
                                          {item.relevanceAnalysis}
                                        </p>
                                      ) : null}
                                      {item.videoAnalysis ? (
                                        <p>
                                          <b>
                                            AI-generated 'Video Analysis'
                                            Summary:
                                          </b>{" "}
                                          {item.videoAnalysis}
                                        </p>
                                      ) : null}
                                      {item.VIDEO_Duration > 10 ? (
                                        item.audioAnalysis ? (
                                          <p>
                                            <b>
                                              AI-generated 'Audio Analysis'
                                              Summary:
                                            </b>{" "}
                                            {item.audioAnalysis
                                              ? item.audioAnalysis
                                              : parseInt(
                                                  item.Overall_score < 10
                                                    ? item.Overall_score
                                                    : (
                                                        item.Overall_score / 10
                                                      ).toFixed(1)
                                                ) > 5
                                              ? "🙌 Congrats. Your voice input and quality-of-communication looks good. keep it up "
                                              : "❌ Sorry. Your voice input and quality-of-communication needs betterment. Keep practising"}
                                          </p>
                                        ) : null
                                      ) : (
                                        <p>
                                          <b>Warning:</b> {item.audioAnalysis}
                                        </p>
                                      )} */}
                                      <hr />
                                      {/* {Overall score code} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <br />
                            <div className="mt-10">
                              <div
                                style={{ maxWidth: 480 }}
                                className="player-wrapper"
                              >
                                <ReactPlayer
                                  className="react-player"
                                  controls
                                  url={item.videoUrl}
                                  width="100%"
                                  height="100%"
                                  playsInline
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className="mt-10">
                          {item.type === "voiceInput" && (
                            <div>
                              {/* {item.Overall_score = item.Overall_score<10 ? item.Overall_score :(item.Overall_score/10).toFixed(1) } */}
                              {item.SampleAnswer ? (
                                <p style={{ fontSize: 15 }}>
                                  <b>Benchmark Response:</b> {item.SampleAnswer}
                                </p>
                              ) : null}
                              <hr />
                              {item.relevanceAnalysis ? (
                                <p>
                                  <b>
                                    AI-Generated 'Relevance Analysis' Summary :
                                  </b>{" "}
                                  {item.relevanceAnalysis}
                                </p>
                              ) : null}

                              {item.audioAnalysis ? (
                                <p>
                                  <b>AI-generated 'Audio Analysis' Summary:</b>{" "}
                                  {item.audioAnalysis
                                    ? item.audioAnalysis
                                    : parseInt(
                                        item.Overall_score < 10
                                          ? item.Overall_score
                                          : (item.Overall_score / 10).toFixed(1)
                                      ) > 5
                                    ? "🙌 Congrats. Your voice input and quality-of-communication looks good. keep it up "
                                    : "❌ Sorry. Your voice input and quality-of-communication needs betterment. Keep practising"}
                                </p>
                              ) : null}

                              <div className="mt-10">
                                <audio controls>
                                  <source
                                    src={item.audioUrl}
                                    type="audio/mpeg"
                                  />
                                </audio>

                                {/* <AudioPlayer audioUrl={item.audioUrl} /> */}
                                {/* <CustomAudioPlayer audioUrl={item.audioUrl} /> */}
                              </div>
                              <hr />
                              {/* {Overall score code} */}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}

                    <hr />

                    {isIELTSscoring == true &&
                      OnlymediaArray.map((item, index) => (
                        <div key={index} className="faq-item">
                          <p style={{ marginTop: 30 }} className="question">
                            <b>{`Question ${index + 1}`}</b>
                            {`: ${item.BotText}`}
                          </p>
                          <br />

                          <div className="mt-10">
                            <div
                              style={{ maxWidth: 480 }}
                              className="player-wrapper"
                            >
                              <ReactPlayer
                                className="react-player"
                                controls
                                url={item.videoUrl}
                                width="100%"
                                height="100%"
                                playsInline
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    <div>
                      {/* {showNote ? (
                <p>
                  ** the overall score is reduced because video duration is less
                  than 40 seconds
                </p>
              ) : null}{" "} */}
                      <p>
                        <b>* Definitions of Key Concepts: </b>
                      </p>
                      <p>
                        <b>AI-generated Relevance analysis:</b> Our algorithm
                        checks if your answer is relevant to the question.
                      </p>{" "}
                      <p>
                        <b>AI-generated Communication analysis:</b> Our
                        algorithm analyzes how well you spoke, including your
                        grammar, pronunciation, and word choice
                      </p>
                      <p>
                        <b>AI-generated Executive Presence analysis: </b>
                        Our algorithm assesses your body language, like eye
                        contact and facial expressions.
                      </p>{" "}
                      <p>
                        <b>Disclaimer:</b> While our algorithms provide valuable
                        insights, human judgment is essential for a
                        comprehensive analysis. Algorithms may not accurately
                        capture all nuances of spoken language or non-verbal
                        cues. We recommend consulting a human expert for a
                        complete evaluation.
                      </p>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>

            <div className="row text-center">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <p>
                  Reach us for support at :{" "}
                  <a href="mailto:hello@lifeskillsbharat.com">
                    hello@lifeskillsbharat.com
                  </a>
                </p>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UserReport;
